import React from 'react'

const MuniPrivacyPolicy = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Privacy Policy</span>

      <span className='legal_content_body_text'>
        Your privacy is important to us. It is Muni Yazilim ve Teknoloji
        A.S&#39;s policy to respect your privacy and comply with any applicable
        law and regulation regarding any personal information we may collect
        about you, including across our website,{' '}
        <a
          href='http://www.muni.com.tr/'
          class='s2'
          target='_blank'
          rel='noreferrer'
        >
          https://www.muni.com.tr
        </a>
        <a
          href='http://www.muni.com.tr/'
          class='a'
          target='_blank'
          rel='noreferrer'
        >
          ,
        </a>{' '}
        and other sites we own and operate.
      </span>

      <span className='legal_content_body_text'>
        Personal information is any information about you which can be used to
        identify you. This includes information about you as a person (such as
        name, address, and date of birth), your devices, payment details, and
        even information about how you use a website or online service.
      </span>

      <span className='legal_content_body_text'>
        In the event our site contains links to third-party sites and services,
        please be aware that those sites and services have their own privacy
        policies. After following a link to any third-party content, you should
        read their posted privacy policy information about how they collect and
        use personal information. This Privacy Policy does not apply to any of
        your activities after you leave our site.
      </span>

      <span className='legal_content_body_text'>
        This policy is effective as of 27 November 2021. Last updated: 06
        December 2021
      </span>
      <span className='legal_content_body_title'>Information We Collect</span>

      <span className='legal_content_body_text'>
        Information we collect falls into one of two categories: “voluntarily
        provided” information and “automatically collected” information.
      </span>

      <span className='legal_content_body_text'>
        “Voluntarily provided” information refers to any information you
        knowingly and actively provide us when using or participating in any of
        our services and promotions.
      </span>

      <span className='legal_content_body_text'>
        “Automatically collected” information refers to any information
        automatically sent by your devices in the course of accessing our
        products and services.
      </span>
      <span className='legal_content_body_title'>Log Data</span>

      <span className='legal_content_body_text'>
        When you visit our website, our servers may automatically log the
        standard data provided by your web browser. It may include your device’s
        Internet Protocol (IP) address, your browser type and version, the pages
        you visit, the time and date of your visit, the time spent on each page,
        and other details about your visit.
      </span>

      <span className='legal_content_body_text'>
        Additionally, if you encounter certain errors while using the site, we
        may automatically collect data about the error and the circumstances
        surrounding its occurrence. This data may include technical details
        about your device, what you were trying to do when the error happened,
        and other technical information relating to the problem. You may or may
        not receive notice of such errors, even in the moment they occur, that
        they have occurred, or what the nature of the error is.
      </span>

      <span className='legal_content_body_text'>
        Please be aware that while this information may not be personally
        identifying by itself, it may be possible to combine it with other data
        to personally identify individual persons.
      </span>

      <span className='legal_content_body_title'>Device Data</span>

      <span className='legal_content_body_text'>
        When you visit our website or interact with our services, we may
        automatically collect data about your device, such as:
      </span>

      <ul id='l1'>
        <li>
          <span className='legal_content_body_text'>Device Type</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Operating System</span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Unique device identifiers
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>Device settings</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Geo-location data</span>

          <span className='legal_content_body_text'>
            Data we collect can depend on the individual settings of your device
            and software. We recommend checking the policies of your device
            manufacturer or software provider to learn what information they
            make available to us.
          </span>
          <span className='legal_content_body_title'>Personal Information</span>

          <span className='legal_content_body_text'>
            We may ask for personal information — for example, when you
            subscribe to our newsletter or when you contact us — which may
            include one or more of the following:
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>Name</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Email</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Social media profiles</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Date of birth</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Phone/mobile number</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Home/mailing address</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Job title</span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            National identity number or SSN
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>Company tax ID</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Company tax authority</span>
        </li>
        <li>
          <span className='legal_content_body_text'>IP addresses</span>

          <span className='legal_content_body_title'>Transaction Data</span>

          <span className='legal_content_body_text'>
            Transaction data refers to data that accumulates over the normal
            course of operation on our platform. This may include transaction
            records, stored files, user profiles, analytics data and other
            metrics, as well as other types of information, created or
            generated, as users interact with our services.
          </span>

          <span className='legal_content_body_title'>
            Legitimate Reasons for Processing Your Personal Information
          </span>

          <span className='legal_content_body_text'>
            We only collect and use your personal information when we have a
            legitimate reason for doing so. In which instance, we only collect
            personal information that is reasonably necessary to provide our
            services to you.
          </span>
          <span className='legal_content_body_title'>
            Collection and Use of Information
          </span>

          <span className='legal_content_body_text'>
            We may collect personal information from you when you do any of the
            following on our website:
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Register for an account
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Purchase a subscription
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Enter any of our competitions, contests, sweepstakes, and surveys
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Sign up to receive updates from us via email or social media
            channels
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Use a mobile device or web browser to access our content
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Contact us via email, social media, or on any similar technologies
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            When you mention us on social media
          </span>

          <span className='legal_content_body_text'>
            We may collect, hold, use, and disclose information for the
            following purposes, and personal information will not be further
            processed in a manner that is incompatible with these purposes:
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to provide you with our platform&#39;s core features and services
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to enable you to customize or personalize your experience of our
            website
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to contact and communicate with you
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            for analytics, market research, and business development, including
            to operate and improve our website, associated applications, and
            associated social media platforms
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            for advertising and marketing, including to send you promotional
            information about our products and services and information about
            third parties that we consider may be of interest to you
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to consider your employment application
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to enable you to access and use our website, associated
            applications, and associated social media platforms
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            for internal record keeping and administrative purposes
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to run competitions, sweepstakes, and/or offer additional benefits
            to you
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to comply with our legal obligations and resolve any disputes that
            we may have
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            to attribute any content (e.g. posts and comments) you submit that
            we publish on our website
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            for security and fraud prevention, and to ensure that our sites and
            apps are safe, secure, and used in line with our terms of use
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            for technical assessment, including to operate and improve our app,
            associated applications, and associated social media platforms
          </span>

          <span className='legal_content_body_text'>
            We may combine voluntarily provided and automatically collected
            personal information with general information or research data we
            receive from other trusted sources. For example, If you provide us
            with your location, we may combine this with general information
            about currency and language to provide you with an enhanced
            experience of our site and service.
          </span>

          <span className='legal_content_body_title'>
            Security of Your Personal Information
          </span>

          <span className='legal_content_body_text'>
            When we collect and process personal information, and while we
            retain this information, we will protect it within commercially
            acceptable means to prevent loss and theft, as well as unauthorized
            access, disclosure, copying, use, or modification.
          </span>

          <span className='legal_content_body_text'>
            Although we will do our best to protect the personal information you
            provide to us, we advise that no method of electronic transmission
            or storage is 100% secure, and no one can guarantee absolute data
            security.
          </span>

          <span className='legal_content_body_text'>
            You are responsible for selecting any password and its overall
            security strength, ensuring the security of your own information
            within the bounds of our services. For example, ensuring any
            passwords associated with accessing your personal information and
            accounts are secure and confidential.
          </span>
          <span className='legal_content_body_title'>
            How Long We Keep Your Personal Information
          </span>

          <span className='legal_content_body_text'>
            We keep your personal information only for as long as we need to.
            This time period may depend on what we are using your information
            for, in accordance with this privacy policy. For example, if you
            have provided us with personal information as part of creating an
            account with us, we may retain this information for the duration
            your account exists on our system. If your personal information is
            no longer required for this purpose, we will delete it or make it
            anonymous by removing all details that identify you.
          </span>

          <span className='legal_content_body_text'>
            However, if necessary, we may retain your personal information for
            our compliance with a legal, accounting, or reporting obligation or
            for archiving purposes in the public interest, scientific, or
            historical research purposes or statistical purposes.
          </span>

          <span className='legal_content_body_title'>Children’s Privacy</span>

          <span className='legal_content_body_text'>
            We do not aim any of our products or services directly at children
            under the age of 13, and we do not knowingly collect personal
            information about children under 13.
          </span>

          <span className='legal_content_body_title'>
            Disclosure of Personal Information to Third Parties
          </span>

          <span className='legal_content_body_text'>
            We may disclose personal information to:
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            a parent, subsidiary, or affiliate of our company
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            third-party service providers for the purpose of enabling them to
            provide their services, including (without limitation) IT service
            providers, data storage, hosting and server providers, ad networks,
            analytics, error loggers, debt collectors, maintenance or
            problem-solving providers, marketing or advertising providers,
            professional advisors, and payment systems operators
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            our employees, contractors, and/or related entities
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            our existing or potential agents or business partners
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            sponsors or promoters of any competition, sweepstakes, or promotion
            we run
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            credit reporting agencies, courts, tribunals, and regulatory
            authorities, in the event you fail to pay for goods or services we
            have provided to you
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            courts, tribunals, regulatory authorities, and law enforcement
            officers, as required by law, in connection with any actual or
            prospective legal proceedings, or in order to establish, exercise,
            or defend our legal rights
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            third parties, including agents or sub-contractors, who assist us in
            providing information, products, services, or direct marketing to
            you
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            third parties to collect and process data
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            an entity that buys, or to which we transfer all or substantially
            all of our assets and business
          </span>

          <span className='legal_content_body_text'>
            Third parties we currently use include:
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>Google Analytics</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Hotjar</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Hubspot</span>
        </li>
        <li>
          <span className='legal_content_body_text'>Google Adsense</span>
        </li>
        <li>
          <span className='legal_content_body_text'>AdColony</span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            Birlesik Odeme Hizmetleri ve Elektronik Para A.S.
          </span>
        </li>
      </ul>

      <span className='legal_content_body_title'>
        International Transfers of Personal Information
      </span>

      <span className='legal_content_body_text'>
        The personal information we collect is stored and/or processed in
        Turkey, Germany, United States, Italy, Ireland, United Kingdom, France,
        and Sweden, or where we or our partners, affiliates, and third-party
        providers maintain facilities.
      </span>

      <span className='legal_content_body_text'>
        The countries to which we store, process, or transfer your personal
        information may not have the same data protection laws as the country in
        which you initially provided the information. If we transfer your
        personal information to third parties in other countries: (i) we will
        perform those transfers in accordance with the requirements of
        applicable law; and (ii) we will protect the transferred personal
        information in accordance with this privacy policy.
      </span>
      <span className='legal_content_body_title'>
        Your Rights and Controlling Your Personal Information
      </span>

      <span class='s3' className='legal_content_body_text'>
        Your choice:
        <span class='p'>
          By providing personal information to us, you understand we will
          collect, hold, use, and disclose your personal information in
          accordance with this privacy policy. You do not have to provide
          personal information to us, however, if you do not, it may affect your
          use of our website or the products and/or services offered on or
          through it.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Information from third parties:
        <span class='p'>
          If we receive personal information about you from a third party, we
          will protect it as set out in this privacy policy. If you are a third
          party providing personal information about somebody else, you
          represent and warrant that you have such person’s consent to provide
          the personal information to us.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Marketing permission:
        <span class='p'>
          If you have previously agreed to us using your personal information
          for direct marketing purposes, you may change your mind at any time by
          contacting us using the details below.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Access:
        <span class='p'>
          You may request details of the personal information that we hold about
          you.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Correction:
        <span class='p'>
          If you believe that any information we hold about you is inaccurate,
          out of date, incomplete, irrelevant, or misleading, please contact us
          using the details provided in this privacy policy. We will take
          reasonable steps to correct any information found to be inaccurate,
          incomplete, misleading, or out of date.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Non-discrimination:
        <span class='p'>
          We will not discriminate against you for exercising any of your rights
          over your personal information. Unless your personal information is
          required to provide you with a particular service or offer (for
          example processing transaction data), we will not deny you goods or
          services and/or charge you different prices or rates for goods or
          services, including through granting discounts or other benefits, or
          imposing penalties, or provide you with a different level or quality
          of goods or services.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Downloading of Personal Information:
        <span class='p'>
          We provide a means for you to download the personal information you
          have shared through our site. Please contact us for more information.
        </span>
      </span>
      <span class='s3' className='legal_content_body_text'>
        Notification of data breaches:
        <span class='p'>
          We will comply with laws applicable to us in respect of any data
          breach.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Complaints:
        <span class='p'>
          If you believe that we have breached a relevant data protection law
          and wish to make a complaint, please contact us using the details
          below and provide us with full details of the alleged breach. We will
          promptly investigate your complaint and respond to you, in writing,
          setting out the outcome of our investigation and the steps we will
          take to deal with your complaint. You also have the right to contact a
          regulatory body or data protection authority in relation to your
          complaint.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Unsubscribe:
        <span class='p'>
          To unsubscribe from our email database or opt-out of communications
          (including marketing communications), please contact us using the
          details provided in this privacy policy, or opt-out using the opt-out
          facilities provided in the communication. We may need to request
          specific information from you to help us confirm your identity.
        </span>
      </span>

      <span className='legal_content_body_title'>Use of Cookies</span>

      <span className='legal_content_body_text'>
        We use “cookies” to collect information about you and your activity
        across our site. A cookie is a small piece of data that our website
        stores on your computer, and accesses each time you visit, so we can
        understand how you use our site. This helps us serve you content based
        on preferences you have specified.
      </span>

      <span className='legal_content_body_text'>
        Please refer to our Cookie Policy for more information.
      </span>

      <span className='legal_content_body_title'>Business Transfers</span>

      <span className='legal_content_body_text'>
        If we or our assets are acquired, or in the unlikely event that we go
        out of business or enter bankruptcy, we would include data, including
        your personal information, among the assets transferred to any parties
        who acquire us. You acknowledge that such transfers may occur, and that
        any parties who acquire us may, to the extent permitted by applicable
        law, continue to use your personal information according to this policy,
        which they will be required to assume as it is the basis for any
        ownership or use rights we have over such information.
      </span>
      <span className='legal_content_body_title'>Limits of Our Policy</span>

      <span className='legal_content_body_text'>
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and policies of
        those sites, and cannot accept responsibility or liability for their
        respective privacy practices.
      </span>

      <span className='legal_content_body_title'>Changes to This Policy</span>

      <span className='legal_content_body_text'>
        At our discretion, we may change our privacy policy to reflect updates
        to our business processes, current acceptable practices, or legislative
        or regulatory changes. If we decide to change this privacy policy, we
        will post the changes here at the same link by which you are accessing
        this privacy policy.
      </span>

      <span className='legal_content_body_text'>
        If the changes are significant, or if required by applicable law, we
        will contact you (based on your selected preferences for communications
        from us) and all our registered users with the new details and links to
        the updated or changed policy.
      </span>

      <span className='legal_content_body_text'>
        If required by law, we will get your permission or give you the
        opportunity to opt in to or opt out of, as applicable, any new uses of
        your personal information.
      </span>

      <span className='legal_content_body_title'>
        Additional Disclosures for Australian Privacy Act Compliance (AU)
        International Transfers of Personal Information
      </span>
      <span className='legal_content_body_text'>
        Where the disclosure of your personal information is solely subject to
        Australian privacy laws, you acknowledge that some third parties may not
        be regulated by the Privacy Act and the Australian Privacy Principles in
        the Privacy Act. You acknowledge that if any such third party engages in
        any act or practice that contravenes the Australian Privacy Principles,
        it would not be accountable under the Privacy Act, and you will not be
        able to seek redress under the Privacy Act.
      </span>

      <span className='legal_content_body_title'>
        Additional Disclosures for General Data Protection Regulation (GDPR)
        Compliance (EU)
      </span>

      <span className='legal_content_body_title'>
        Data Controller / Data Processor
      </span>
      <span className='legal_content_body_text'>
        The GDPR distinguishes between organisations that process personal
        information for their own purposes (known as “data controllers”) and
        organizations that process personal information on behalf of other
        organizations (known as “data processors”). We, Muni Yazilim ve
        Teknoloji A.S., located at the address provided in our Contact Us
        section, are a Data Controller and/or Processor with respect to the
        personal information you provide to us.
      </span>

      <span className='legal_content_body_title'>
        Legal Bases for Processing Your Personal Information
      </span>

      <span className='legal_content_body_text'>
        We will only collect and use your personal information when we have a
        legal right to do so. In which case, we will collect and use your
        personal information lawfully, fairly, and in a transparent manner. If
        we seek your consent to process your personal information, and you are
        under 16 years of age, we will seek your parent or legal guardian’s
        consent to process your personal information for that specific purpose.
      </span>

      <span className='legal_content_body_text'>
        Our lawful bases depend on the services you use and how you use them.
        This means we only collect and use your information on the following
        grounds:
      </span>

      <span className='legal_content_body_title'>Consent From You</span>

      <span className='legal_content_body_text'>
        Where you give us consent to collect and use your personal information
        for a specific purpose. You may withdraw your consent at any time using
        the facilities we provide; however this will not affect any use of your
        information that has already taken place. You may consent to providing
        your name and contact details for the purpose of entering a giveaway or
        promotion. While you may withdraw your entry at any time, this will not
        affect any selection or judging that has already taken place. If you
        have any further enquiries about how to withdraw your consent, please
        feel free to enquire using the details provided in the Contact Us
        section of this privacy policy.
      </span>

      <span className='legal_content_body_title'>
        Performance of a Contract or Transaction
      </span>

      <span className='legal_content_body_text'>
        Where you have entered into a contract or transaction with us, or in
        order to take preparatory steps prior to our entering into a contract or
        transaction with you. For example, if you purchase a product, service,
        or subscription from us, we may need to use your personal and payment
        information in order to process and deliver your order.
      </span>

      <span className='legal_content_body_title'>Our Legitimate Interests</span>

      <span className='legal_content_body_text'>
        Where we assess it is necessary for our legitimate interests, such as
        for us to provide, operate, improve and communicate our services. We
        consider our legitimate interests to include research and development,
        understanding our audience, marketing and promoting our services,
        measures taken to operate our services efficiently, marketing analysis,
        and measures taken to protect our legal rights and interests.
      </span>

      <span className='legal_content_body_title'>Compliance with Law</span>

      <span className='legal_content_body_text'>
        In some cases, we may have a legal obligation to use or keep your
        personal information. Such cases may include (but are not limited to)
        court orders, criminal investigations, government requests, and
        regulatory obligations. If you have any further enquiries about how we
        retain personal information in order to comply with the law, please feel
        free to enquire using the details provided in the Contact Us section of
        this privacy policy.
      </span>

      <span className='legal_content_body_title'>
        International Transfers Outside of the European Economic Area (EEA)
      </span>

      <span className='legal_content_body_text'>
        We will ensure that any transfer of personal information from countries
        in the European Economic Area (EEA) to countries outside the EEA will be
        protected by appropriate safeguards, for example by using standard data
        protection clauses approved by the European Commission, or the use of
        binding corporate rules or other legally accepted means.
      </span>

      <span className='legal_content_body_title'>
        Your Rights and Controlling Your Personal Information
      </span>

      <span class='s3' className='legal_content_body_text'>
        Restrict:
        <span class='p'>
          You have the right to request that we restrict the processing of your
          personal information if (i) you are concerned about the accuracy of
          your personal information; (ii) you believe your personal information
          has been unlawfully processed; (iii) you need us to maintain the
          personal information solely for the purpose of a legal claim; or (iv)
          we are in the process of considering your objection in relation to
          processing on the basis of legitimate interests.
        </span>
      </span>
      <span class='s3' className='legal_content_body_text'>
        Objecting to processing:
        <span class='p'>
          You have the right to object to processing of your personal
          information that is based on our legitimate interests or public
          interest. If this is done, we must provide compelling legitimate
          grounds for the processing which overrides your interests, rights, and
          freedoms, in order to proceed with the processing of your personal
          information.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Data portability:
        <span class='p'>
          You may have the right to request a copy of the personal information
          we hold about you. Where possible, we will provide this information in
          CSV format or other easily readable machine format. You may also have
          the right to request that we transfer this personal information to a
          third party.
        </span>
      </span>

      <span class='s3' className='legal_content_body_text'>
        Deletion:
        <span class='p'>
          You may have a right to request that we delete the personal
          information we hold about you at any time, and we will take reasonable
          steps to delete your personal information from our current records. If
          you ask us to delete your personal information, we will let you know
          what information we are able to delete after consulting with the
          company that signed you up for our products and services and let you
          know how the deletion affects your use of our website or products and
          services. There may be exceptions to this right for specific legal
          reasons which, if applicable, we will set out for you in response to
          your request If your company terminates or deletes your account, we
          will continue to retain any and all information that pertains to the
          activities of your company, e.g. company expenses, company card
          transactions, etc. We will not be able to delete this information
          unless your company agrees to its deletion since this information will
          be considered as property of your company. Please be aware that search
          engines and similar third parties may still retain copies of your
          personal information that has been made public at least once, like
          certain profile information and public comments, even after you have
          deleted the information from our services or deactivated your account.
        </span>
      </span>

      <span className='legal_content_body_title'>Contact Us</span>

      <span className='legal_content_body_text'>
        For any questions or concerns regarding your privacy, you may contact us
        at{'  '}
        <a href='mailto:help@muni.com.tr' target='_blank' rel='noreferrer'>
          help@muni.com.tr
        </a>
        .
      </span>
      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniPrivacyPolicy
