import React from 'react'

const MuniTermsAndConditions = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Terms of Service</span>

      <span className='legal_content_body_text'>
        These Terms of Service govern your use of the website located at{' '}
        <a href='http://www.muni.com.tr/' class='a' target='_blank' rel="noreferrer">
          https://www.muni.com.tr
        </a>{' '}
        and any related services provided by Muni Yazilim ve Teknoloji A.S..
      </span>

      <span className='legal_content_body_text'>
        By accessing{' '}
        <a href='http://www.muni.com.tr/' class='a' target='_blank' rel="noreferrer">
          https://www.muni.com.tr
        </a>
        <a href='http://www.muni.com.tr/' class='s3' target='_blank' rel="noreferrer">
          ,
        </a>{' '}
        you agree to abide by these Terms of Service and to comply with all
        applicable laws and regulations. If you do not agree with these Terms of
        Service, you are prohibited from using or accessing this website or
        using any other services provided by Muni Yazilim ve Teknoloji A.S..
      </span>

      <span className='legal_content_body_text'>
        We, Muni Yazilim ve Teknoloji A.S., reserve the right to review and
        amend any of these Terms of Service at our sole discretion. Upon doing
        so, we will update this page. Any changes to these Terms of Service will
        take effect immediately from the date of publication.
      </span>

      <span className='legal_content_body_text'>
        These Terms of Service were last updated on 06 December 2021.
      </span>

      <span className='legal_content_body_title'>Limitations of Use</span>

      <span className='legal_content_body_text'>
        By using this website, you warrant on behalf of yourself, your users,
        and other parties you represent that you will not:
      </span>

      <ol id='l1'>
        <li>
          <span className='legal_content_body_text'>
            modify, copy, prepare derivative works of, decompile, or reverse
            engineer any materials and software contained on this website;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            remove any copyright or other proprietary notations from any
            materials and software on this website;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            transfer the materials to another person or “mirror” the materials
            on any other server;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            knowingly or negligently use this website or any of its associated
            services in a way that abuses or disrupts our networks or any other
            service Muni Yazilim ve Teknoloji A.S. provides;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            use this website or its associated services to transmit or publish
            any harassing, indecent, obscene, fraudulent, or unlawful material;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            use this website or its associated services in violation of any
            applicable laws or regulations;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            use this website in conjunction with sending unauthorized
            advertising or spam;
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            harvest, collect, or gather user data without the user’s consent; or
          </span>
        </li>
        <li>
          <span className='legal_content_body_text'>
            use this website or its associated services in such a way that may
            infringe the privacy, intellectual property rights, or other rights
            of third parties.
          </span>
        </li>
      </ol>

      <span className='legal_content_body_title'>Intellectual Property</span>

      <span className='legal_content_body_text'>
        The intellectual property in the materials contained in this website are
        owned by or licensed to Muni Yazilim ve Teknoloji A.S. and are protected
        by applicable copyright and trademark law. We grant our users permission
        to download one copy of the materials for personal, non-commercial
        transitory use.
      </span>

      <span className='legal_content_body_text'>
        This constitutes the grant of a license, not a transfer of title. This
        license shall automatically terminate if you violate any of these
        restrictions or the Terms of Service, and may be terminated by Muni
        Yazilim ve Teknoloji A.S. at any time.
      </span>

      <span className='legal_content_body_title'>Liability</span>

      <span className='legal_content_body_text'>
        Our website and the materials on our website are provided on an &#39;as
        is&#39; basis. To the extent permitted by law, Muni Yazilim ve Teknoloji
        A.S. makes no warranties, expressed or implied, and hereby disclaims and
        negates all other warranties including, without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property, or other
        violation of rights.
      </span>

      <span className='legal_content_body_text'>
        In no event shall Muni Yazilim ve Teknoloji A.S. or its suppliers be
        liable for any consequential loss suffered or incurred by you or any
        third party arising from the use or inability to use this website or the
        materials on this website, even if Muni Yazilim ve Teknoloji A.S. or an
        authorized representative has been notified, orally or in writing, of
        the possibility of such damage.
      </span>
      <span className='legal_content_body_text'>
        In the context of this agreement, “consequential loss” includes any
        consequential loss, indirect loss, real or anticipated loss of profit,
        loss of benefit, loss of revenue, loss of business, loss of goodwill,
        loss of opportunity, loss of savings, loss of reputation, loss of use
        and/or loss or corruption of data, whether under statute, contract,
        equity, tort (including negligence), indemnity, or otherwise.
      </span>

      <span className='legal_content_body_text'>
        Because some jurisdictions do not allow limitations on implied
        warranties, or limitations of liability for consequential or incidental
        damages, these limitations may not apply to you.
      </span>

      <span className='legal_content_body_title'>Accuracy of Materials</span>

      <span className='legal_content_body_text'>
        The materials appearing on our website are not comprehensive and are for
        general information purposes only. Muni Yazilim ve Teknoloji A.S. does
        not warrant or make any representations concerning the accuracy, likely
        results, or reliability of the use of the materials on this website, or
        otherwise relating to such materials or on any resources linked to this
        website.
      </span>

      <span className='legal_content_body_title'>Links</span>

      <span className='legal_content_body_text'>
        Muni Yazilim ve Teknoloji A.S. has not reviewed all of the sites linked
        to its website and is not responsible for the contents of any such
        linked site. The inclusion of any link does not imply endorsement,
        approval, or control by Muni Yazilim ve Teknoloji A.S. of the site. Use
        of any such linked site is at your own risk and we strongly advise you
        make your own investigations with respect to the suitability of those
        sites.
      </span>

      <span className='legal_content_body_title'>Right to Terminate</span>

      <span className='legal_content_body_text'>
        We may suspend or terminate your right to use our website and terminate
        these Terms of Service immediately upon written notice to you for any
        breach of these Terms of Service.
      </span>

      <span className='legal_content_body_title'>Severance</span>
      <span className='legal_content_body_text'>
        Any term of these Terms of Service which is wholly or partially void or
        unenforceable is severed to the extent that it is void or unenforceable.
        The validity of the remainder of these Terms of Service is not affected.
      </span>

      <span className='legal_content_body_title'>Governing Law</span>

      <span className='legal_content_body_text'>
        These Terms of Service are governed by and construed in accordance with
        the laws of Turkey. You irrevocably submit to the exclusive jurisdiction
        of the courts in that State or location.
      </span>
      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniTermsAndConditions
