import React from 'react'

import loginSS from "../../../assets/company/appSS_login.png"
import homeSS from "../../../assets/company/appSS_home.png"
import settingsSS from "../../../assets/company/appSS_settings.png"
import myAccountSS from "../../../assets/company/appSS_myAccount.png"
import privacyDeleteSS from "../../../assets/company/appSS_privacyDelete.png"

const MuniTermsAndConditions = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Account Deletion Request</span>

      <span className='legal_content_body_text'>
        Per Privacy and Data Protection regulations in most countries, you can request to delete your
        account at Muni and <b>all your personal information.</b>
      </span>

      <span className='legal_content_body_text'>
        However, you need to note that non-personal information that you have entered on Muni's applications
        do not belong to you. This information is owned solely by your company and can only be deleted at 
        your company's behest.
      </span>

      <span className='legal_content_body_title'>How to Request Account Deletion</span>

      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Step 1</b><br/>You have to request the deletion of your account using our mobile apps. Begin by signing
        into your account on your Muni app. Make sure that you have the latest update as privacy
        delete is not available in earlier versions.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Step 2</b><br/>Once signed in, use the bottom navigation of your app to go to the 'Menu'
        section. 
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Step 3</b><br/>From the many options provided in the 'Menu' section, choose 'My Account'.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Step 4</b><br/>In your account section, you will find a new option available for you 'Request 
        Account Deletion'.
      </span>
      <span className='legal_content_body_text'>
        <b className='legal_content_body_text_heading'>Step 5</b><br/>This is the final step. Here, you can read all about what it means to request 
        a deletion of your account for privacy reasons. Once ready, click on this text to request the deletion of your account.
      </span>

      <span className='legal_content_body_title'>Illustrative Explanation of the Steps</span>
      
      <div className='legal_media'>
          <img src={loginSS} alt="Login Screenshot" /> 
          <img src={homeSS} alt="Login Screenshot" /> 
          <img src={settingsSS} alt="Login Screenshot" /> 
          <img src={myAccountSS} alt="Login Screenshot" /> 
          <img src={privacyDeleteSS} alt="Login Screenshot" /> 
      </div>


      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniTermsAndConditions
