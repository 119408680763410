import React from 'react'

const MuniCookiePolicy = () => {
  return (
    <div className='legal_content'>
      <div className='legal_anchor' />
      <span className='legal_content_header'>Çerez Politikası</span>

      <span className='legal_content_body_text'>
        <a href='http://www.muni.com.tr/' target='_blank' rel='noreferrer'>
          https://www.muni.com.tr
        </a>
        adresindeki web sitemizin deneyiminizi geliştirmek için çerezler kullanıyoruz. Bu çerez politikası, Muni Yazılım ve Teknoloji A.Ş.'nin gizlilik politikasının bir parçasıdır ve cihazınız ile sitemiz arasında çerez kullanımını kapsar.
      </span>

      <span className='legal_content_body_text'>
        Ayrıca, hizmetlerinin bir parçası olarak çerez kullanabilecek üçüncü taraf hizmetleri hakkında temel bilgiler sağlarız. Bu politika, onların çerezlerini kapsamaz.
      </span>

      <span className='legal_content_body_text'>
        Eğer bizden çerez kabul etmek istemiyorsanız, tarayıcınızı 
        <a href='http://www.muni.com.tr/' target='_blank' rel='noreferrer'>
          https://www.muni.com.tr
        </a>
        adresinden çerezleri reddedecek şekilde ayarlamanız gerekir. Bu durumda, istediğiniz bazı içerik ve hizmetleri sağlayamayabiliriz.
      </span>

      <span className='legal_content_body_title'>Çerez nedir?</span>

      <span className='legal_content_body_text'>
        Çerez, ziyaret ettiğiniz bir web sitesinin cihazınıza depoladığı küçük bir veri parçasıdır. Genellikle web sitesi hakkında bilgiler, sitenin web tarayıcınızı dönüşte tanımasını sağlayan benzersiz bir tanımlayıcı, çerezin amacına hizmet eden ek veriler ve çerezin kendisinin ömrünü içerir.
      </span>

      <span className='legal_content_body_text'>
        Çerezler, belirli özellikleri etkinleştirmek (örn. giriş yapma), site kullanımını izlemek (örn. analitik), kullanıcı ayarlarınızı saklamak (örn. zaman dilimi, bildirim tercihleri) ve içeriğinizi kişiselleştirmek (örn. reklam, dil) için kullanılır.
      </span>

      <span className='legal_content_body_text'>
        Ziyaret ettiğiniz web sitesi tarafından ayarlanan çerezler genellikle birinci taraf çerezleri olarak adlandırılır. Bunlar genellikle yalnızca o belirli site üzerindeki etkinliğinizi izler.
      </span>

      <span className='legal_content_body_text'>
        Diğer siteler ve şirketler (yani üçüncü taraflar) tarafından ayarlanan çerezler üçüncü taraf çerezleri olarak adlandırılır. Bunlar, aynı üçüncü taraf hizmetini kullanan diğer web sitelerinde sizi izlemek için kullanılabilir.
      </span>
      <span className='legal_content_body_title'>
        Çerez türleri ve kullanım şeklimiz Temel Çerezler
      </span>
      <span className='legal_content_body_text'>
        Temel çerezler, bir web sitesinin deneyiminiz için hayati önem taşıyan kullanıcı girişleri, hesap yönetimi, alışveriş sepetleri ve ödeme işlemleri gibi temel özellikleri etkinleştirmek için gereklidir.
      </span>

      <span className='legal_content_body_text'>
        Web sitemizde belirli işlevleri etkinleştirmek için temel çerezleri kullanıyoruz.
      </span>

      <span className='legal_content_body_title'>Performans Çerezleri</span>

      <span className='legal_content_body_text'>
        Performans çerezleri, ziyaretiniz sırasında bir web sitesini nasıl kullandığınızı izler. Tipik olarak, bu bilgiler anonim ve toplu olarak, tüm site kullanıcıları arasında izlenen bilgilerle birlikte toplanır. Şirketlerin ziyaretçi kullanım kalıplarını anlamalarına, karşılaşabilecekleri sorunları veya hataları tanımlamalarına ve izleyicilerinin genel web sitesi deneyimini iyileştirmek için daha iyi stratejik kararlar almalarına yardımcı olurlar.
      </span>
      <span className='legal_content_body_text'>
        Bu çerezler, ziyaret ettiğiniz web sitesi (birinci taraf) veya üçüncü taraf hizmetler tarafından ayarlanabilir. Sizinle ilgili kişisel bilgiler toplamazlar.
      </span>

      <span className='legal_content_body_text'>
        Sitemizde performans çerezleri kullanıyoruz.
      </span>

      <span className='legal_content_body_title'>İşlevsellik Çerezleri</span>

      <span className='legal_content_body_text'>
        İşlevsellik çerezleri, ziyaret ettiğiniz web sitesinde yapılandırdığınız ayarlar (dil ve zaman dilimi ayarları gibi) ve cihazınız hakkında bilgi toplamak için kullanılır. Bu bilgilerle, web siteleri size özelleştirilmiş, geliştirilmiş veya optimize edilmiş içerik ve hizmetler sağlayabilir. Bu çerezler, ziyaret ettiğiniz web sitesi (birinci taraf) veya üçüncü taraf hizmetler tarafından ayarlanabilir.
      </span>

      <span className='legal_content_body_text'>
        Sitemizde seçili özellikler için işlevsellik çerezleri kullanıyoruz.
      </span>
      <span className='legal_content_body_title'>
        Hedefleme/Reklam Çerezleri
      </span>

      <span className='legal_content_body_text'>
        Hedefleme/reklam çerezleri, hangi tanıtım içeriğinin size ve ilgi alanlarınıza en uygun ve uygun olduğunu belirlemeye yardımcı olur. Web siteleri, hedeflenmiş reklamcılık sunmak veya bir reklamı ne kadar sık gördüğünüzü sınırlamak için bunları kullanabilir. Bu, şirketlerin kampanyalarının etkinliğini ve size sunulan içeriğin kalitesini artırmalarına yardımcı olur. Bu çerezler, ziyaret ettiğiniz web sitesi (birinci taraf) veya üçüncü taraf hizmetler tarafından ayarlanabilir.
      </span>
      <span className='legal_content_body_text'>
        Aynı üçüncü taraf hizmetini kullanan diğer web sitelerinde sizi izlemek için üçüncü taraf hizmetler tarafından ayarlanan hedefleme/reklam çerezleri kullanılır.
      </span>

      <span className='legal_content_body_text'>
        Sitemizde hedefleme/reklam çerezleri kullanıyoruz.
      </span>
      <div className='legal_content_ending_container'></div>
    </div>
  )
}

export default MuniCookiePolicy
